import * as config from './config';

export function healthcheck() {
  if (config.enableStaticConfig) {
    return Promise.resolve(true);
  } else {
    return fetch(`${config.apiBaseUrl}/health`);
  }
}

const behaviorsPath = config.enableStaticConfig ? `${process.env.PUBLIC_URL}/config/behaviors.json` : `${config.apiBaseUrl}/behaviors`;
const keycodesPath  = config.enableStaticConfig ? `${process.env.PUBLIC_URL}/config/keycodes.json` : `${config.apiBaseUrl}/keycodes`;
const keymapPath    = config.enableStaticConfig ? `${process.env.PUBLIC_URL}/config/keymap.json` : `${config.apiBaseUrl}/keymap`;
const layoutPath    = config.enableStaticConfig ? `${process.env.PUBLIC_URL}/config/layout.json` : `${config.apiBaseUrl}/layout`;

export function loadBehaviours() {
  return fetch(behaviorsPath).then(response => response.json());
}

export function loadKeycodes() {
  return fetch(keycodesPath).then(response => response.json());
}

export function loadKeymap() {
  return fetch(keymapPath).then(response => response.json());
}

export function loadLayout() {
  return fetch(layoutPath).then(response => response.json());
}

export function remoteCompile(dtsKeymap) {
  return fetch(`${config.apiBaseUrl}/compile`, {
    method: 'POST',
    body: dtsKeymap,
  }).then(response => response.blob());
}
