// From https://stackoverflow.com/a/35251739
export function downloadFile(name, contents, mime_type) {
  mime_type = mime_type || "text/plain";
  var blob = new Blob([contents], {type: mime_type});
  downloadFileBlob(name, blob);
}

export function downloadFileBlob(name, blob) {
  var dlink = document.createElement('a');
  dlink.download = name;
  dlink.href = window.URL.createObjectURL(blob);
  dlink.onclick = function(e) {
    // revokeObjectURL needs a delay to work properly
    var that = this;
    setTimeout(function() {
      window.URL.revokeObjectURL(that.href);
    }, 1500);
  };

  dlink.click();
  dlink.remove();
}
